.conform-popup-header-text {
  color: var(--text-colour-secondary-262626, #262626);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}
.conform-popup-sub-text {
  color: var(--text-colour-body-525252, #525252);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.iconClass {
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.release-popup-main {
  display: flex;
  justify-content: space-between;
}
.popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-top: 24px;
  .cancelBtn {
    .dx-button-content {
      .dx-button-text {
        background-color: white !important;
        color: #262626;
        font-family: "Inter", sans-serif;
        font-weight: 500;
      }
      background-color: white !important;
    }
  }
  .saveBtn {
    .dx-button-content {
      .dx-button-text {
        font-family: "Inter", sans-serif;
        color: white;
      }
    }
  }

  .dx-button:nth-child(1) {
    box-shadow: none;
    border: 1px solid #c6c6c6;
  }
}

.dx-popup-content {
  padding: 24px 14px;
}
