@media only screen and (min-width: 1300px) and (min-height: 900px) {
  .container {
    height: 100vh;
    width: 100%;

    .Header {
      height: 76px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #c6c6c6;
      padding: 0px 16px 0px 16px;

      .logo {
        height: 47px;
        width: 174px;
      }
    }

    .main-body {
      height: 92vh;
      width: 100%;
      display: flex;
      .Left-Side {
        height: 92vh;
        width: 60%;
        background-color: #f3faf8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .Image-title {
          font-size: 30px;
          display: flex;
          flex-direction: column;
          color: #234b4c;
          font-weight: 500;
          height: 152px;
        }

        .image {
          height: 400px;
          width: 405px;
        }
      }
      .Right-Side {
        height: 92vh;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        .selection-area {
          height: 330px;
          width: 461px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .selection-area-section {
            height: 86px;
            display: flex;
            display: flex;
            .discription-section {
              height: 56px;
              width: 345px;
              display: flex;
              flex-direction: column;
              gap: 5px;
              .title {
                font-size: 18px;
                color: #161616;
              }
              .sub-title {
                font-size: 14px;
                color: #525252;
              }
            }
          }
        }
      }
    }
  }

  .title-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .text1 {
      color: #161616;
      font-size: 20px;
    }
    .text2 {
      color: #525252;
      font-size: 16px;
    }
  }

  .choosing-area {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #53aea8;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.2) inset;
    .forward-btn {
      background-color: #318280;
      border-radius: 25px;
      color: white !important;
      .dx-button-content {
        .dx-icon.dx-icon-arrowright {
          color: white;
        }
      }
    }
  }
}

.container {
  height: 100vh;
  width: 100%;

  .Header {
    height: 76px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c6c6c6;
    padding: 16px;

    .logo {
      height: 47px;
      width: 174px;
    }
    .Links {
      color: #234b4c;
    }
  }

  .main-body {
    height: 90vh;
    width: 100%;
    display: flex;
    .Left-Side {
      height: 92vh;
      width: 60%;
      background-color: #f3faf8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .Image-title {
        font-size: 36px;
        display: flex;
        flex-direction: column;
        color: #234b4c;
        font-weight: 500;
        height: 152px;
      }

      .image {
        height: 450px;
        width: 465px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .Right-Side {
      height: 92vh;
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      .selection-area {
        height: 330px;
        width: 461px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .selection-area-section {
          height: 86px;
          display: flex;
          display: flex;
          .discription-section {
            height: 56px;
            width: 345px;
            display: flex;
            flex-direction: column;
            gap: 0px;
            .title {
              font-size: 18px;
              color: #161616;
            }
            .sub-title {
              font-size: 14px;
              color: #525252;
            }
          }
        }
      }
    }
  }
}

.title-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .text1 {
    color: #161616;
    font-size: 20px;
    font-weight: 500;
  }
  .text2 {
    color: #525252;
    font-size: 16px;
  }
}

.choosing-area {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #53aea8;
  border-radius: 4px;
  box-shadow: 0 2px 16px 0px rgba(0, 0, 0, 0.04);

  .forward-btn {
    background-color: #318280;
    border-radius: 25px;
    color: white !important;
    .dx-button-content {
      .dx-icon.dx-icon-arrowright {
        color: white;
      }
    }
  }
}

.sign-links {
  font-weight: 500;
}
