// .form-avatar {
//   float: left;
//   height: 120px;
//   width: 120px;
//   margin-right: 20px;
//   border: 1px solid rgba(0, 0, 0, 0.1);
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-color: #fff;
//   overflow: hidden;

//   img {
//     height: 120px;
//     display: block;
//     margin: 0 auto;
//   }
// }

.profile-content-header-block {
  height: 140px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c6c6c6;
  align-items: center;
  padding: 16px 24px;
  //justify-content: flex-start;
  align-items: flex-start;
}
.profile-content-header-block-inner{
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  width: 100%;
}
.profile-title-section {
  display: flex;
  align-items: stretch;
}
.title-section-last {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 50px;
}
.profile-detalis {
  margin-left: 30px;
  // margin-top: 5px;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
}
.user-image-container {
  margin-top: 16px;
}
.user-image-container {
  position: relative;

  &:hover {
    background-color: #fff;
  }
  > label {
    > img {
      width: 98px;
      height: 98px;
      cursor: pointer;
      border-radius: 50%;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  .hover-icon {
    background-color: #fff;
    padding: 5px;
    border-radius: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
    cursor: pointer;
  }
}
.profile-changepwd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 25px;
  height: auto;
}
.profile-changepwd > * {
  flex: 1;
}
.datagrid-container-class{
  border-bottom: 1px solid #c6c6c6;
}
.main-content-div{
  padding: 24px 0px 33px 24px;
  border-bottom: 1px solid #c6c6c6;
}

.edit-content-block{
  padding: 24px;
  gap: 17px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c6c6c6;
}