.vendor-category {
  .vendor-category-card {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-section {
      .add-btn {
        border: 1px solid #c6c6c6;
        .dx-button-content,
        .dx-button-text {
          color: #262626;
          font-weight: 500;
          // font-size: 18px;
          font-family: "Inter", sans-serif;
          background-color: white;
        }
      }
    }
  }
}

.add-popup-main-container {
  height: 100%;
  // border: 2px solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .popup-footer {
    padding-top: 0px;
  }
}

// .dx-overlay-content {
//   box-shadow: none !important;
// }
.dx-overlay-shader {
  .dx-overlay-content.dx-popup-normal.dx-resizable {
    box-shadow: none !important;
  }
}

// .dx-popup-wrapper > .dx-overlay-content {
//   box-shadow: none !important;
// }
