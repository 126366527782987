// .auth-content-header-block {
//   height: 140px;
//   font-size: 20px;
//   display: flex;
//   flex-direction: column;
//   border-bottom: 1px solid #c6c6c6;
//   align-items: center;
//   padding: 16px 24px;
//   //justify-content: flex-start;
//   align-items: flex-start;
// }
// .auth-content-header-block-inner {
//   padding: 16px 24px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   font-size: 20px;
//   width: 100%;
// }
// .auth-title-section {
//   display: flex;
//   align-items: stretch;
// }
// .popup-icon {
//   background-color: transparent;
// }
// .seachBox {
//   .dx-button {
//     background: transparent;
//   }
// }
.main-content-div{
  border-top: 1px solid #c6c6c6;
}
.tree-list-main {
//   .header-toolbar-modules{
//     .sub-text{
//       font-family: inter;
//       font-size: 18px;
//     }
//   }
  .dx-treelist-header-panel {
    // padding: 0 5;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c6c6c6;

    .sub-text{
      font-family: inter;
      font-size: 18px;
      font-weight: 500px;
    }

    .dx-treelist-search-panel {
      width: 341px !important;
      height: 48px !important;
      background: transparent;
      border: 1px solid #c6c6c6;
      border-radius: 5px;
    }
    .report-right {
      width: 168px !important;
      height: 44px !important;
      background: transparent;
      border: 1px solid #c6c6c6;
      border-radius: 5px;
    }
  }
  .dx-toolbar-items-container {
    // border: 2px solid;
    //
    margin-bottom: 20px;
  }
}

.auth-container{
  padding: 0px 24px;
  // .content-block{
  //   border: 1px solid #c6c6c6;
  //   border-radius: 4px;
  // }
}
