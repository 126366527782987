.dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-toolbar-mini.dx-popup-title{
    display: none !important;

}

.custom-btn{

    &.dx-button {
        min-width: 36px;
        height: 36px;
        position: relative;
        border-radius: 50%;
        background-color: #318280;
        color: rgb(255 255 255);
    }
}

.popup-container{  

    display: flex;
    flex-direction: column;

    .header-icon{
        background-color:#f3faf8;
        padding:10px;
        border-radius: 4px;
    }

    .PopupHeader{
    padding: 5px 0px;
        display: flex;
        justify-content: space-between;
    }

    .PopupBody{
        display: flex;
        flex-direction: column;
       gap: 5px;
        justify-content: center;
        height: 84px;
        .body-main-container{
            font-size: 18px;
            font-weight: 500;
            color: #262626;
        }

        .body-sub-container{
            font-size: 14px;
            color: #525252;
        }
        
    }

    .popupfooter{
        height: 60px;
        display: flex;
        gap: 20px;
        align-items: center;
        .popop-discard-btn{
            .dx-button-content{
                background-color: #ffff !important ;
                .dx-button-text{
                    background-color: #ffff;
                    color:#262626;
                    font-weight: 400;
                    font-size: 14px;
                }

            }
            
            color: #262626 !important;
        }
    }
}