.content-header-block {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content-header-block-inner {
    display: flex;
    height: 50px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    align-items: center;
  }

  .col-main {
    &[data-type="Approved"] {
      background-color: rgba(18, 77, 34, 0.06);
    }

    &[data-type="Pending"] {
      background-color: rgba(6, 84, 139, 0.06);
    }

    &[data-type="Rejected"] {
      background-color: rgba(173, 24, 32, 0.06);
    }
    // background-color: rgba(18, 77, 34, 0.08);
    border-radius: 16px;
    padding: 4px 8px;
    margin-left: 8px;

    .status-text {
      margin-left: 20px;
    }
  }

  .status-circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    margin-right: -13px;
    margin-bottom: 2px;
  }

  span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    // margin-left: 18px;
    &[data-type="Approved"] {
      .status-circle {
        background-color: #124d22;
      }

      color: #124d22;
    }

    &[data-type="Pending"] {
      .status-circle {
        background-color: #06548b;
      }

      color: #06548b;
    }
    &[data-type="Rejected"] {
      .status-circle {
        background-color: #ad1820;
      }

      color: #ad1820;
    }
  }

  .rejectBtn {
    border: 1px solid #c6c6c6;
    .dx-button-content,
    .dx-button-text {
      color: #262626;
      background-color: white;
    }
  }

  .btn-section {
    display: flex;
    gap: 8px;
  }
}
