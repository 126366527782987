@import "./themes/generated/variables.base.scss";

@import "./themes/generated/variables.base.scss";

@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-display: fallback;
  font-weight: 100 700;
  src: url(./assets/icon-font.woff2) format("woff2");
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;

  .content {
    line-height: 1.5;
    flex-grow: 1;

    h2 {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .container {
    height: 100%;
    flex-direction: column;
    display: flex;
  }

  .layout-body {
    flex: 1;
    min-height: 0;
  }

  .content-block {
    margin-top: 20px;
  }
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px);
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;
  background-color: #ffff !important;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: #ffff !important;
  }
}

$side-panel-min-width: 99px;

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

//--- icon ---

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  user-select: none;

  &.solid {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
  }
}

.dx-scrollable-content {
  background-color: #ffff;
  color: #262626;
}

.dx-button-has-text .dx-button-content,
.dx-button-has-text .dx-button-content .dx-button-text {
  background-color: #318280;
  color: #ffff;
  font-size: 14px !important;
}

.dx-texteditor.dx-state-active::before,
.dx-texteditor.dx-state-focused::before {
  border-bottom: black;
  transform: scale(1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
}

// ------------------------------------------------------

.dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened::before {
  content: "\f016";
  display: inline-block !important;
  color: #262626 !important;
}

.dx-treeview-toggle-item-visibility {
  right: 20px;
  left: auto;
  color: #262626 !important;
}

.dx-placeholder {
  color: #262626;
}

.dx-button-text {
  font-weight: 300;
  font-size: 14px;
}

.dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable {
  box-shadow: none;
}

.dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text {
  box-shadow: none;
}

// Material icon css
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  cursor: pointer;

  &.solid {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
  }
}
