.source-panel {
  display: flex;
  gap: 1rem;
}

.add-btn-section {
  .add-btn {
    border: 0px solid #c6c6c6;
    .dx-button-content,
    .dx-button-text {
      color: #318280;
      font-weight: 500;
      // font-size: 18px;
      font-family: "Inter", sans-serif;
      background-color: white;
    }
  }
}

.uplaod_btn {
  width: 49%;
  height: 48px;
  border: 1px dashed #9d9b9b;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  word-spacing: 2px;
  font-size: 14px;
  border-radius: 4px;
}