.index-card-container {
  // border: 2px solid;
  padding: 24px !important;
  .dx-card {
    box-shadow: none;
    // border: 1px solid #e0e0e0 !important;
  }
  // margin-top: 0px !important;
  .index-card-heading {
    font-size: 18px;
    // margin-bottom: 1rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // gap: 1.5rem;
    color: #262626;
    font-family: Inter;
    font-weight: 400;
    line-height: 12.606px;
    letter-spacing: 0.567px;
    white-space: nowrap;
    .index-card-title {
      cursor: pointer;
    }

    .icon {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 320px) {
  .index-card-container {
    padding: 0;

    .index-card-heading {
      gap: 0.5rem;
      font-size: 16px;
    }

    .grid-container {
      padding: 0;
      margin-bottom: 2rem;

      .dx-card {
        width: 280px;
      }
    }
  }
}

@media only screen and (min-width: 320px) {
  .index-card-heading {
    gap: 0.5rem;
    font-size: 18px;

    .grid-container {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .index-card-container {
    padding: 0;

    .index-card-heading {
      gap: 0.5rem;
      font-size: 18px;
    }
  }
}
