.notifydropdown {
  position: absolute;
  z-index: 999;
  right: 20px;
  top: 0.5rem;
  width: 300px;
  height: 98% !important;
  border-radius: 13.841px;
  border-bottom: 4px solid #53AEA8;
  background: #fff;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.5);
  animation: notifydropdownanimation 0.3s both ease-in-out;

  @keyframes notifydropdownanimation {
    0% {
      opacity: 0;
      height: 0;
    }

    100% {
      opacity: 1;
      height: 90vh;
    }
  }

  .notifydropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    background-color: #318280;
    padding: 0.8rem 1rem;
    color: #fff;

    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

    .heading {
      font-size: 16px;
    }

    .sub-heading {
      color: var(--default-gray-20, #f3faf8);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .rfcbtn {
      transition: transform 0.3s ease-in-out;
      cursor: pointer;
    }

    .dx-button.dx-state-active {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    .readall-btn{
      cursor: pointer;
    }
  }

  .notifydropdown-body {
    overflow: auto;
    height: 85vh;

    .notification {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0.9rem;
      // border-bottom: 1px solid #F4F4F4;
      cursor: pointer;
      // margin-bottom: .1rem;

      .notify-title {
        margin-bottom: 0.5rem;
        color: #525252;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .notify-time {
        color: #8d8d8d;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .notify-unread {
        background-color: #318280;
        height: 7px;
        width: 7px;
        border-radius: 50%;
      }
    }
  }
}

.notifydropdown {
  .dx-item.dx-tab.dx-tab-selected {
    border-bottom: 2px solid #0057a1;
  }

  .dx-item.dx-tab.dx-tab-selected.dx-state-focused {
    border-bottom: 2px solid #0057a1;
  }

  .dx-item.dx-tab.dx-tab-selected.dx-state-hover.dx-state-focused {
    border-bottom: 2px solid #0057a1;
  }

  .dx-item.dx-tab.dx-tab-selected.dx-state-focused.dx-state-hover {
    border-bottom: 2px solid #0057a1;
  }
}