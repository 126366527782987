.verify-container {
    display: flex;
    width: 100%;
    height: calc(100vh - 72px);
    color: #234b4c;
    // font-size: 40px;
    // font-weight: 500;
    font-family: "Inter", sans-serif;
    background-color: #f3faf8;
    height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .qr-container{
        width: 400px !important;
        // height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:40px !important;
        // gap:16px;
        // .qr-container-text-area{
        //     display: flex;
        //     flex-direction: column;
        //     font-family: "Inter", sans-serif;
        //     font-weight: 500;
        //     display: flex;
        //     text-align: center;
        // }


        .qr-container-text-area{
            // padding: 16px 0px;
            display: flex;
            flex-direction: column;
            // gap:16px;
            .qr-container-text{
                font-family: "Inter", sans-serif;
                font-weight: 600;
                // display: flex;
                text-align: center;
               
            }
            // .qr-container-sub-text{
            //     font-family: "Inter", sans-serif;
            //     display: flex;
            //     justify-content: center;
            //     text-align: center;
            // }
        }

        .qr-container-footer{
            width: 100%;
            .print-qr{
                .dx-button-content{
                    background: white;
                    .dx-button-text{
                        background: white;
                        color: black;
                    }
                }
            }
        }

    }

    .success-container{
        width: 450px !important;
        height: 237px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:32px;
        .reject-svg{
            width: 73px;
            height: 52px;
        }
        .pending-svg{
            width: 67.5px;
            height: 67.5px;
        }

        .verify-container-text-area{
            // padding: 16px 0px;
            display: flex;
            flex-direction: column;
            gap:16px;
            .verify-container-text{
                font-family: "Inter", sans-serif;
                font-weight: 500;
                display: flex;
                text-align: center;
               
            }
            .verify-container-sub-text{
                font-family: "Inter", sans-serif;
                display: flex;
                justify-content: center;
                text-align: center;
            }
        }
       
    }
  
  
  }