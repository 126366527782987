.general-main {
  margin-top: 16px;
  .dx-card-box {
    // border: 1px solid #c6c6c6;
    border-radius: 4px;
    padding: 20px 20px 20px 8px;

    .dx-card-title {
      font-size: 18px;
      font-family: "Inter", sans-serif;
      // font-weight: 100;
    }

  }
  .dx-hr-line{
    border-top: 1px solid #e0e0e0;
  }
}
