.tabpanel {
  .widget-container {
    .dx-theme-background-color {
      // border: 1px solid #c6c6c6;
      border-radius: 4px;
      margin: 8px 8px;
      // padding: 8px;
      height: calc(100vh - 33vh);
      .dx-tabpanel-tabs {
        .dx-state-hover {
          background-color: transparent;
        }
        .dx-state-focused {
          background-color: transparent;
        }
      }

      .dx-tabpanel-container {
        padding: 8px;
      }
    }
  }
}
