// //---------------- Registration Form Css --------------------

// // .content-header-block-inner {
// //   display: flex;
// //   justify-content: space-between;
// //   height: 140px;
// //   font-size: 20px;
// //   display: flex;
// //   width: 100%;
// //   // border-bottom: 1px solid #c6c6c6;
// //   border: none !important;
// //   align-items: center;
// //   padding-right: 10px;
// //   padding-left: 10px;
// // }
// .content-header-block {
// }

/* width */
::-webkit-scrollbar {
  width: 6px;
}

.required {
  span:nth-child(1)::after {
    color: red !important; /* Change color of the asterisk */
    content: " *"; /* Add asterisk after the first character */
  }

  span.dx-clear-button-area:first-of-type::after {
    color: rgb(255, 255, 255) !important; /* Change color of the asterisk */
    content: ""; /* Add asterisk after the first character */
  }
  span.dx-icon-clear:first-of-type::after {
    color: rgb(255, 255, 255) !important; /* Change color of the asterisk */
    content: ""; /* Add asterisk after the first character */
  }
}

// .required {
//   span:first-child::after {
//     color: red !important; /* Change color of the asterisk */
//     content: " *"; /* Add asterisk after the first character */
//   }
// }
.dx-invalid-message > .dx-overlay-content {
  color: #f44336;
  background-color: transparent;
  border-radius: 4px;
  padding: 4px 0px !important;
  line-height: 16px;
  font-size: 12px;
  // top: 70px !important;
}

/* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #c6c6c6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-add-form {
  margin: 8px 24px 0px 24px;
  height: auto;
  border: 1px solid #c6c6c6;
  // border-bottom: none;
  padding-bottom: 16px;
  border-radius: 4px 4px 0px 0px;

  .form-header-panel {
    width: 100%;

    ul {
      display: flex;
      height: 48px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0px;
      //   border: 2px solid;
      border-bottom: 1px solid #c6c6c6;
      margin: 8px 16px;
      align-items: center;
      li {
        // width: auto;
        padding: 13px;
        font-size: 14px;
        align-items: center;
        a {
          color: #262626;
          font-weight: 500;
          font-family: "Inter", sans-serif !important;
          text-decoration: none;
        }
      }
      li.active {
        border-bottom: 3px solid #318280;
      }
    }
  }
}
.form-body-content:hover {
  overflow-y: scroll;
}
.form-body-content {
  padding: 0px 16px;
  overflow: auto;
  height: auto;
  height: calc(100vh - 40vh);
  .header-text {
    font-size: 18px;
    font-weight: 500;
    font-family: "Inter", sans-serif !important;
  }
  .header-sub-text {
    font-size: 14px;
    font-weight: 500;
    font-family: "Inter", sans-serif !important;
    margin-top: 24px;
  }

  .form-section:last-child {
    border-bottom: none; /* Remove border bottom from the fifth div */
  }

  .form-section {
    padding: 24px 0px;
    border-bottom: 1px solid #c6c6c6;

    .bill_addTxt {
      margin-top: 16px;
    }

    .section-title {
      display: flex;
      flex-direction: column;
      gap: 40px;
      z-index: -1;
      padding-top: 24px;
      padding-bottom: 16px;

      .dx-texteditor-label {
        font-size: 14px;
        color: #525252;
        font-family: "Inter", sans-serif !important;
      }

      .common-row {
        display: flex;
        gap: 16px;

        .dx-state-focused {
          border-bottom: 2px solid #318280;

          transform: scale(1);
          transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
        }

        .verify-email-text {
          .dx-button {
            background-color: #318280;
            height: 32px;
            min-width: 32px;
            .dx-icon {
              height: 15px;
              width: 15px;
            }
          }
        }
        .dx-texteditor-input-container {
          .dx-texteditor-input {
            // border: 2px solid;
            background-color: #f4f4f4 !important;
            color: #262626 !important;
          }
        }
      }
      .common-row-check {
        display: flex;
        gap: 16px;
        .dx-texteditor-input {
          padding-left: 0px;
        }

        .verify-email-text {
          .dx-button {
            background-color: #318280;
            height: 32px;
            min-width: 32px;
            .dx-icon {
              height: 15px;
              width: 15px;
            }
          }
        }
        .dx-texteditor-input-container {
          .dx-texteditor-input {
            // border: 2px solid;
            background-color: white !important;
            // background-color: #f4f4f4 !important;
            color: #262626 !important;
          }
        }
      }
    }

    .docSection {
      display: flex;
      flex-direction: column;
      .text-section {
        font-size: 14px;
        color: #525252;
      }
      .doc-sub-section {
        // border: 2px solid;
        margin-top: 4px;
        // width: 349px;
        .sub-section {
          display: flex;
          padding: 12px;
          text-decoration: none;
          cursor: pointer;
          gap: 8px;
          .text {
            cursor: pointer;
            font-size: 14px;
            color: #525252;
          }
        }
      }
    }
  }

  .uplaod_btn {
    width: 49%;
    height: 48px;
    border: 1px dashed #9d9b9b;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    word-spacing: 2px;
    font-size: 14px;
    border-radius: 4px;
  }

  //   .section-title:last-child {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 1rem;
  //     border-bottom: none;
  //   }
}

// .section-title-header {
//   font-weight: 500;
//   font-size: 20px;
// }

// .form-style {
//   border-bottom: none;
// }

// .verify-email-text {
//   .dx-button {
//     background-color: #318280;
//     height: 32px;
//     min-width: 32px;
//     .dx-icon {
//       height: 15px;
//       width: 15px;
//     }
//   }
// }
