@import "../../themes/generated/variables.base.scss";

$card-xxl: 350px;
$card-lg: 320px;
$card-md: 300px;
$card-sm: 280px;

$card-number-xxl: 1fr 1fr 1fr 1fr;
$card-number-lg: 1fr 1fr 1fr;
$card-number-md: 1fr 1fr;
$card-number-sm: 1fr;

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  user-select: none;

  &.solid {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
  }
}
.dx-card {
  .dx-icon {
    // font-size: 20px;
    // color: #525252;
  }
}
.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  grid-template-columns: $card-number-xxl;

  a {
    text-decoration: none;
  }

  .dx-card {
    width: 350px !important;
    min-width: 350px;
    margin-top: 24px;
    padding: 16px;
    border-radius: 8px;
    background: #fff;
    // border-bottom: 4px solid #0057a1;
    // box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    height: 76px;
    border: 1px solid #e0e0e0;

    .card-header {
      .title {
        display: flex;
        padding-top: 8px;
        gap: 0.6rem;
        color: #262626;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        align-items: center;

        .card-icon.material-symbols-outlined {
          font-size: 20px;
          font-weight: 400;
        }
        .arrow-up {
          margin-left: auto;
        }

        &.skeleton {
          min-height: 26px;
          width: 70%;
          border-radius: 4px;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .grid-container {
    grid-template-columns: $card-number-sm;

    .dx-card {
      width: $card-lg;
    }
  }
}

@media only screen and (min-width: 450px) {
  .grid-container {
    grid-template-columns: $card-number-sm;

    .dx-card {
      width: $card-xxl;
    }
  }
}

@media only screen and (min-width: 600px) {
  .grid-container {
    grid-template-columns: $card-number-sm;

    .dx-card {
      width: $card-xxl;
    }
  }
}

@media only screen and (min-width: 768px) {
  .grid-container {
    grid-template-columns: $card-number-md;

    .dx-card {
      width: $card-md;
    }
  }
}

@media only screen and (min-width: 840px) {
  .grid-container {
    grid-template-columns: $card-number-md;

    .dx-card {
      width: $card-lg;
    }
  }
}

@media only screen and (min-width: 920px) {
  .grid-container {
    grid-template-columns: $card-number-md;

    .dx-card {
      width: $card-lg;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: $card-number-lg;

    .dx-card {
      width: $card-sm;
    }
  }
}

@media only screen and (min-width: 1120px) {
  .grid-container {
    grid-template-columns: $card-number-lg;

    &.card-number-xxl {
      grid-template-columns: $card-number-xxl;
    }
    .dx-card {
      width: $card-xxl;

      &.card-lg {
        width: $card-lg + 20px;
      }

      &.card-md {
        width: $card-md;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .grid-container {
    grid-template-columns: $card-number-lg;

    &.card-number-xxl {
      grid-template-columns: $card-number-xxl;
    }

    // &.card-number-lg {
    //     grid-template-columns: $card-number-lg;
    //     gap: 25px;
    // }

    .dx-card {
      width: $card-xxl;

      &.card-lg {
        width: $card-lg + 30px;
      }

      &.card-md {
        width: $card-md + 5px;
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .grid-container {
    grid-template-columns: $card-number-xxl;

    &.card-number-xxl {
      grid-template-columns: $card-number-xxl;
    }

    .dx-card {
      width: $card-md;

      &.card-lg {
        width: $card-lg - 10px;
      }

      &.card-md {
        width: $card-md;
      }
    }
  }
}
