.vendor-listing {
  .dx-datagrid-header-panel {
    border-bottom: 1px solid #c6c6c6;
    border-top: 1px solid #c6c6c6;
    margin-bottom: 16px;
    padding: 24px;

    .dx-datagrid-search-panel,
    .selectbox-right {
      background-color: #fff;
      border: 1px solid #c6c6c6;
      border-radius: 4px;
    }

    .dx-toolbar-after {
      padding-inline-start: 0px;
      .dx-toolbar-item {
        padding-inline-start: 16px;
      }
    }
  }
  .dx-datagrid-headers,
  .dx-datagrid-rowsview {
    margin: 0px 24px;
  }
  .on-hover-data {
    .dx-checkbox-container {
      .dx-checkbox-icon {
        border-radius: 25px;
        // color: #c6c6c6;
      }
    }
  }
}
