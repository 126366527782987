.otpInput {
  height: 66px;
  width: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  &:focus {
    border-color: #53aea8;
  }
}
.dx-popup-content.dx-popup-content-scrollable {
  padding: 24px 16px;
}
.otp-main {
  display: flex;
  margin-top: -16px;
  width: 100%;
  justify-content: space-around;
}
.otpContinuebtn {
  height: 44px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
}
.popup-main-title {
  font-size: 18px;
  margin-bottom: 10px;
}
.popup-sub-title {
  font-size: 14px;
  margin-top: 2px;
  color: #525252;
}
.upper-section {
  height: 156px;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  padding: 5px 0px 0px 0px;
  //justify-content: space-around;
  margin: 0px 6px;
  gap: 1rem;
}
.main-container {
  height: 98;
}
.footer-main {
  height: 127px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1.5rem;
  margin: 0px 6px;
}
.popup-header-logo {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3faf8;
}
.otpInput {
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
}

.ResstartLink {
  color: #234b4c;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
}
.Pre-text {
  font-size: 16px;
  color: #525252;
}
