.breadcrumbs {
  display: flex;
  align-items: center;
  // margin: 20px 20px 0px 10px;
}

.breadcrumb-item-text {
  white-space: nowrap;
  text-decoration: none !important;
  color: #0a6ed1;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.breadcrumb-slash {
  margin: 0 5px;
  color: #262626;
}
.breadcrumb-item.last-child {
  .breadcrumb-item-text {
    color: #262626;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-decoration: none !important;
  }
}
.refresh-btn {
  position: relative;
  margin-left: auto;
  color: var(
    --primary-colours-7000057-a-1-brand,
    var(--Default-Blue-Blue-700, #0057a1)
  );
  // text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;
  cursor: pointer;
}
