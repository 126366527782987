.report-items {
  display: flex;
  justify-content: start;
  gap: 20px;
  margin: 0px 24px 16px;
  flex-wrap: wrap;

  .dx-button-content {
    // background: #c6c6c6;
    background-color: #f4f4f4;
  }

  .searhBtn {
    margin-top: 24px;
    // border: 2px solid;
  }

  .dx-button.dx-button-has-icon:not(.dx-button-has-text):not(
      .dx-shape-standard
    ) {
    border-radius: 4px;
    box-shadow: none;
    // border: 2px solid;
  }
}

.dx-texteditor-label {
  font-size: 14px;
  color: #525252;
  font-family: "Inter", sans-serif !important;
}
