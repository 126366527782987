@import "../../themes/generated/variables.base.scss";
.header-component-login {
  height: 72px;
  border-bottom: 1px solid #c6c6c6;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  .link-Section {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    a {
      text-decoration: none;
    }
  }
}
.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}

.login-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 72px);
  color: #234b4c;
  font-size: 40px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  // width: 100vw;
  .login-container-left {
    width: 60%;
    height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f3faf8;
    .login-svg {
      width: 55%;
      padding-bottom: 5rem;
    }
  }

  .login-container-right {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 72px);
    font-family: "Inter", sans-serif;
    padding: 0px 24px;
    .login-form {
      max-width: 460px;
    }
    .header-main-title {
      font-size: 20px;
      font-weight: 500;
    }
    .header-sub-title {
      padding-top: 8px;
      padding-bottom: 32px;
      font-size: 16px;
    }

    .login-container-right-body {
      .inputField {
        margin-bottom: 8px;
        padding-bottom: 24px;
        .dx-editor-filled.dx-texteditor-label-outside {
          margin-top: 24px;
          border-radius: 4px;
        }
      }

      // .chkBox {
      //   border: 2px solid;
      // }
    }
  }
}

// .login-form {
//   width: 461px; /* Take 30% of the available space */
//   height: 461px;
// }

// .login-container-right {
//   .login-form {
//     .login-container-right-header {
//       .header-title {
//         display: flex;
//         flex-direction: column;
//         .header-main-title {
//           font-size: 32px;
//           font-weight: 400;
//         }
//         .header-sub-title {
//           font-size: 16px;
//           color: #525252;
//         }
//       }
//     }

//     .login-container-right-body {
//       display: flex;
//       flex-direction: column;
//       gap: 2rem;
//     }
//   }
// }

// .login-form {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }

// .login-container-right-body {
//   .dx-checkbox-container {
//     .dx-checkbox-icon {
//       border-radius: 5px;
//     }
//   }
// }
