@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  height: 76px;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }

  .dx-toolbar-items-container {
    height: 76px;
    border-bottom: 1px solid #c6c6c6;
  }

  

  
  .txtlabel {
    font-size: 16px;
    font-weight: normal;
    padding-left: 76px !important;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  // width: $side-panel-min-width;
  width: 72px;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
.user-button.dx-state-hover {
  background-color: transparent !important;
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(10deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.vertical-line {
  border: 1px solid #c6c6c6;
  height: 40px;
  // display: inline-block;
}

.notification {
  // border: 1px solid;
  display: flex;
  position: relative;
  // border-right: 2px solid #c6c6c6;
  .bell-icon {
    padding: 12px;
    // margin-top: 5px;
  }
  .bell-icon:hover {
    animation: shake 0.4s ease-in-out;
  }
}

.unread-noti {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
}

.user-menu {
  .dx-menu-item.dx-state-hover {
    border-left: 2px solid #318280;
  }
  .dx-state-disabled {
    .dx-menu-item-text {
      font-weight: 500;
    }
  }
}

.AddBtn {
  // padding-right: 25px;
  // border: 1px solid;
  padding: 8px;
  // padding-right: 24px;
  .dx-button-has-icon {
    min-width: 0px !important;
    width: 25px !important;
    height: 25px !important;
  }
  .AddbtnClass {
    // border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #318280;
    .dx-icon {
      padding-bottom: 2px;
      padding-left: 1px;
      // border:  1px solid black;
      color: white;
    }
  }

  // .dx-button {
  //   position: unset;
  //   display: flex;
  //   justify-content: center;
  // }
}
