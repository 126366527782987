// .Main-body-Container {
//   min-height: 100vh;
//   overflow-y: hidden;
//   color: #161616;

//--------------- Header Logo -------------
.Header-main {
  //position: fixed;
  z-index: 1000;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 76px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c6c6c6;

  .Logo {
    span {
      font-size: 18px;
      color: black;
      font-weight: 500;
    }
  }

  .header-link {
    span {
      color: #234b4c;
    }
  }
}

//   // -------------- Inner Container ----------
//   .Inner-container {
//     // ----------- Navigation Section --------
//     .inner-header-container {
//       height: 40px;
//       margin: 10px 25px 10px 25px;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       .inner-header-navigation {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//       }
//     }
//     // ------------ Inner Body title --------
//     .inner-body-title {
//       height: 80px;
//       margin: 10px 25px 10px 25px;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//       .title {
//         font-size: 20px;
//         font-weight: 500;
//       }
//     }
//   }

//   .inner-body-containt {
//     width: 100%;
//     padding: 10px 50px 10px 25px;
//     overflow-y: auto; /* Enable scrolling */
//     min-height: calc(100vh - 76px - 40px - 80px);

//     .body-holder {
//       border: 1px solid #c6c6c6;
//       border-radius: 4px;
//       height: 75vh;
//       overflow-y: auto;
//       overflow-x: hidden;
//       .form-navbar {
//         position: sticky;
//         top: 0;
//         background-color: white;
//         z-index: 1000;
//         height: 65px;
//         .form-navbar-containt {
//           .navbar-list {
//             display: flex;
//             height: 48px;
//             width: auto;
//             margin: 0 8px;
//             border-bottom: 1px solid #c6c6c6;
//             li {
//               padding: 12px;
//               a {
//                 color: #262626;
//                 text-decoration: none;
//               }
//             }
//           }
//         }
//       }

//       .formSection {
//         width: 100%;
//         margin: 0px 8px;
//         color: black;
//         .section-title {
//           font-size: 18px;
//           font-weight: 400;
//           margin-bottom: 2rem;
//           margin-top: 2rem;
//           color: #262626;

//           // &:first-of-type {
//           //   margin-top: 1rem; // Adjusted top margin for the first section title inside each form section
//           // }
//         }
//         .first-section-title {
//           margin-top: 1rem;
//         }
//         .Address-title {
//           margin-bottom: 1rem;
//           font-weight: 500;
//         }

//         .section-body {
//           width: 99%;
//           border-bottom: 1px solid #c6c6c6;
//           .section-body-row {
//             display: flex;
//             gap: 1rem;
//             margin-bottom: 2.5rem;
//             span {
//               color: #525252;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .Address-title-selector {
//   margin-left: 1rem;
//   margin-bottom: 2rem;
//   margin-top: 1rem;
//   color: #262626;
//   .dx-checkbox-container {
//     gap: 1rem;
//   }
//   .dx-checkbox-icon {
//     border: 1px solid black;
//     border-radius: 4px;
//     // background-color: #318280;
//   }
// }

// .upload-btn {
//   .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text.dx-fileuploader-button {
//     width: 49%;
//     border: 1px solid black;
//     border-radius: 0px;
//     .dx-button-content {
//       background-color: white;

//       .dx-button-text {
//         background-color: white;
//         color: black;
//       }
//     }
//   }
// }

// .uplaod_btn {
//   width: 49%;
//   height: 48px;
//   border: 1px dashed #525252;
//   display: flex;
//   gap: 1rem;
//   justify-content: center;
//   align-items: center;
//   word-spacing: 2px;
//   font-size: 18px;
// }

// //------ OTP Button -------

// .section-body-row {
//   .otp-button {
//     width: 49%;
//     display: flex;
//     flex-direction: row-reverse;
//     align-items: center;
//     .custom-btn {
//       .dx-button-content {
//         .material-symbols-outlined {
//           color: white !important;
//         }
//       }
//     }
//     color: white !important;
//     .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.custom-btn {
//       border-radius: 20px;
//       background-color: #318280;
//       color: white;
//       margin-right: 15px;
//     }
//   }

//   .otp-button {
//     width: 49%;
//     display: flex;
//     flex-direction: row-reverse;
//     align-items: center;
//     .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.custom-btn {
//       border-radius: 20px;
//       background-color: #318280;
//       color: white;
//       margin-right: 15px;
//     }
//   }
// }

// .bread-crums {
//   display: flex;
//   gap: 1rem;
//   > span:first-child {
//     color: #0a6ed1;
//   }
// }

// .refresh-button {
//   color: #0a6ed1;
// }
// .dx-item.dx-list-item.dx-list-item-selected {
//   background-color: #f3faf8 !important;
// }
// .dx-item.dx-list-item.dx-state-hover {
//   background-color: #f3faf8;
//   border-left: 2px solid #318280;
// }
// .dx-item.dx-list-item.dx-list-item-selected.dx-state-hover {
//   background-color: #f3faf8 !important;
// }
