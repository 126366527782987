.release-popup-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding: 16px 0px;
}

.verify-pro-datagrid {
  .dx-toolbar-after {
    width: 100%;
    padding-inline-start: 0px;
    padding: 16px 0px;

    .dx-datagrid-search-panel {
      width: 100% !important;
      margin: 0px 0px 0px 0px !important;
      background-color: white;
      border: 1px solid #c2c2c2;
      border-radius: 4px;
      height: 44px;
    }
  }
  .dx-datagrid-header-panel {
    padding: 0px 0px !important;
    margin-bottom: 24px;
  }
  .dx-header-row {
    background: #fcfcfc;
    border-top: 1px solid #e0e0e0;
  }
}

.cancelQcBtn {
  .dx-button-content {
    background-color: white;

    .dx-button-text {
      background-color: white;
      color: #262626;
    }
  }
}
