@import "../../dx-styles.scss";
@import "../../themes/generated/variables.additional.scss";

.dx-drawer-panel-content,
.dx-overlay-content {
  .menu-container {
    .dx-treeview-with-search {
      .dx-texteditor.dx-editor-filled .dx-lookup-field,
      .dx-texteditor.dx-editor-filled .dx-placeholder::before,
      .dx-texteditor.dx-editor-filled .dx-texteditor-input,
      .dx-texteditor.dx-editor-outlined .dx-lookup-field,
      .dx-texteditor.dx-editor-outlined .dx-placeholder::before,
      .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
        padding: 12px 45px 14px;
      }
    }
  }
  &[style*="width: 99px"] {
    // width: 99px !important;

    border-right: 1px solid #c6c6c6;

    .side-navigation-menu {
      .menu-container {

        .dx-field-value {
          margin-top: 24px;
          width: 64px;
          height: 88px;
          background-color: #fff;

          .dx-button-mode-contained {
            box-shadow: none;
            height: 75px;
          }

          .dx-button-content {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding-block: 0px;
            padding-inline-start: 0px;
            padding-inline-end: 0px;
            height: 93px;
            .dx-icon {
              height: 48px;
              width: 48px;
              font-size: 25px;
              color: #262626 !important;
              margin-inline-end: 0px;
              margin-bottom: 8px !important;
            }
            .dx-button-text {
              background-color: #fff;
              color: #262626 !important;
              font-size: 14px !important;
              font-family: "Inter";
              font-weight: 500;
              margin-bottom: 8px !important;
            }
          }
        }
        .searchHandler {
          display: block;
        }
        background: #fff;

        .dx-treeview {
          margin-top: 16px;
        }

        .dx-treeview-search {
          display: none;
        }
        .dx-treeview-node {
          // border: 2px solid;
          padding-top: 0px;
          width: 67px;
          margin-bottom: 16px;

          .dx-treeview-item {
            padding: 0px 0px;
            .dx-item-content {
              .dx-icon.material-symbols-outlined {
                margin-bottom: 8px !important;
              }
            }
          }
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            height: auto;
            background: none !important;
            border: 0px solid #53aeab !important;
            border-radius: 4px;
            padding: 0px 0px;

            .dx-item-content {
              .dx-icon.material-symbols-outlined {
                background: #f3faf8;
                color: #262626;
                border: 1px solid #53aeab;
                border-radius: 4px;
              }
            }
          }

          .dx-item-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100px !important;
            .dx-icon.material-symbols-outlined {
              height: 44px;
              width: 44px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              // margin-bottom: 8px !important;
            }
          }
        }

        .dx-treeview-expander-icon-stub {
          display: none !important;
        }
        .dx-treeview-toggle-item-visibility {
          display: none !important;
        }
        .dx-treeview {
          white-space: wrap;
          padding: 0rem;
          text-align: center;
        }
      }
    }
    // Add more styles as needed
  }
}

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;
  border-right: #525252;
  justify-content: flex-start;
  background: #ffffff !important;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: 1px solid #c6c6c6 !important;
    padding: 0px 16px;

    .searchHandler {
      display: none;
    }

    .dx-texteditor.dx-editor-filled {
      height: 44px;
      display: flex;
      align-items: center;
      padding: 0px 0px 0px 0px;
      border: 1px solid #c6c6c6;
      border-radius: 4px;
      background-color: white;
    }

    .dx-treeview-search {
      margin-bottom: 0px;
    }

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      padding: 0rem;
      margin-top: 24px;

      .dx-treeview-node-is-leaf {
        // border: 2px solid;
        width: 100%;
        .dx-item-content {
          display: flex;
          align-items: center;

          .dx-icon {
            width: 44px;
            // height: 44px;
          }
        }

        .dx-treeview-expander-icon-stub {
          display: none;
        }
      }

      .dx-treeview-node {
        padding-top: 16px;
        padding-left: 16px;
        &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
          height: 44px;
          background-color: #f3faf8 !important;
          color: #262626 !important;
          border: 1px solid #53aeab !important;
          border-radius: 4px;
        }
        &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
          color: #262626 !important;
        }
      }

      // ##

      // ## Icon width customization
      .dx-treeview-item {
        // border: 2px solid yellow;
        padding-left: 0px;
        flex-direction: row-reverse;

        .dx-item-content {
          .itemTxt {
            font-size: 14px;
            color: #262626;
            font-family: "Inter";
            font-weight: normal;
          }
        }
        .dx-icon {
          width: 48px !important;
          margin: 0 !important;
        }
      }
      // ##

      // ## Arrow customization
      // .dx-treeview-node {
      //   padding: 0 0 !important;
      // }

      .dx-treeview-toggle-item-visibility {
        // right: 20px;
        left: auto;
        color: #262626 !important;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        // left: 10px;
        right: auto;
      }
      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level="1"] {
          // font-weight: bold;
          padding-left: 0px;
        }

        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 15 $side-panel-min-width;
          padding-left: 24px;
        }
      }
      // ##
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container
        .dx-treeview-node.dx-state-selected.dx-state-focused
        > .dx-treeview-item
        * {
        color: inherit;
      }
    }
    // ##
  }
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../utils/patches.scss";
  @include menu-pre-init-patch;
}
