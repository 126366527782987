// .content-header-block {
//     height: 140px;
//     font-size: 20px;
//     display: flex;
//     flex-direction: column;
//     border-bottom: 1px solid #c6c6c6;
//     align-items: center;
//     padding-right: 10px;
//     padding-left: 10px;
//     //justify-content: flex-start;
//     align-items: flex-start;
//   }
//   .content-header-block-inner {
//     display: flex;
//     justify-content: space-between;
//     height: 140px;
//     font-size: 20px;
//     display: flex;
//     width: 100%;
//     border-bottom: 1px solid #c6c6c6;
//     align-items: center;
//     padding-right: 10px;
//     padding-left: 10px;
//   }

// .content-header-block-breadcrumbs{
//   justify-content: flex-start;
// }

//-------------------- On Hover Button ----------------------------
.on-hover-data {
  .dx-row.dx-data-row.dx-row-lines {
    height: 65px !important;
    .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal {
      display: none;
      background-color: none;
      box-shadow: none;
    }
  }
}

.on-hover-data {
  .dx-row.dx-data-row.dx-row-lines:hover {
    .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal {
      display: inline-block;
      opacity: 1 !important;
      background-color: rgba(255, 255, 255, 0);
      box-shadow: none !important;
    }
  }
}

// ------------------------- Customised DataGrid Search && Tool ----------------

// .on-hover-data {
//   .dx-datagrid.dx-gridbase-container.dx-datagrid-borders {
//     .dx-datagrid-header-panel {
//       // padding: 0;
//       // padding-bottom: 24px;
//       .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection {
//         .dx-toolbar-items-container {
//           .dx-item.dx-toolbar-item.dx-toolbar-button {
//             .dx-item-content.dx-toolbar-item-content {
//               .dx-datagrid-search-panel.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-filled.dx-searchbox.dx-show-clear-button.dx-texteditor-empty.dx-widget {
//                 border-bottom: none;
//                 border: 1px solid #525252;
//                 background-color: #ffff;
//                 border-radius: 4px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

.toolbar-text-span {
  color: #525252;
  font-size: 18px;
}

//----- Select Box -----
// .on-hover-data {
//   .dx-item.dx-toolbar-item.dx-toolbar-button {
//     width: 231px;
//     .dx-item-content.dx-toolbar-item-content {
//       .selectbox-right.dx-show-invalid-badge.dx-selectbox.dx-textbox.dx-texteditor.dx-dropdowneditor-button-visible.dx-editor-filled.dx-texteditor-empty.dx-widget.dx-dropdowneditor.dx-dropdowneditor-field-clickable {
//         background-color: #fff;
//         border: 1px solid #525252;
//         border-radius: 4px;
//       }
//     }
//   }
// }
.on-hover-data {
  .dx-item.dx-toolbar-item.dx-toolbar-button:first-child {
    // width: 231px;
    .dx-item-content.dx-toolbar-item-content {
      // margin-right: 16px;

      .selectbox-right.dx-show-invalid-badge.dx-selectbox.dx-textbox.dx-texteditor.dx-dropdowneditor-button-visible.dx-editor-filled.dx-texteditor-empty.dx-widget.dx-dropdowneditor.dx-dropdowneditor-field-clickable {
        background-color: #fff;
        border: 1px solid #525252;
        border-radius: 4px;
      }
    }
  }
}

.on-hover-data {
  .dx-row.dx-data-row.dx-row-lines {
    td {
      .col-main {
        &[data-type="Approved"] {
          background-color: rgba(18, 77, 34, 0.06);
        }

        &[data-type="Pending"] {
          background-color: rgba(6, 84, 139, 0.06);
        }

        &[data-type="Rejected"] {
          background-color: rgba(173, 24, 32, 0.06);
        }
        // background-color: rgba(18, 77, 34, 0.08);
        border-radius: 16px;
        padding: 8px 10px 8px 10px;
      }

      position: relative;

      .status-circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        margin-right: -13px;
        margin-bottom: 2px;
      }

      span {
        &[data-type="Approved"] {
          .status-circle {
            background-color: #124d22;
          }

          color: #124d22;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 21px;
          margin-left: 18px;
        }

        &[data-type="Pending"] {
          .status-circle {
            background-color: #06548b;
          }

          color: #06548b;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 21px;
          margin-left: 18px;
        }
        &[data-type="Rejected"] {
          .status-circle {
            background-color: #ad1820;
          }

          color: #ad1820;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 21px;
          margin-left: 18px;
        }
      }
    }

    .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal {
      display: none;

      .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal {
        display: none;
      }

      .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal {
        display: none;
      }
    }
  }
}
.user-popup {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;
}

.popup-body-main-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}

.userPopup {
  gap: 1rem;
  height: 100%;
  justify-content: space-between;
}

.popupfooter {
  justify-content: flex-end !important;
}
